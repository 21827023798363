/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
    -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;

  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;

  * {
    &:focus, a {
      outline: none;
    }
  }

}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

@charset 'UTF-8';
.slick-dots,
.slick-next,
.slick-prev {
    position: absolute;
    display: block;
    padding: 0
}

.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-next,
.slick-prev {
    z-index: 10;
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
    opacity: 1
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: .25
}

.slick-next:before,
.slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: 1;
    color: #fff
}

.slick-prev {
    left: 25px
}

[dir=rtl] .slick-prev {
    right: 25px;
    left: auto
}

.slick-prev:before {
    content: ' ';
    background-image: url(/static/media/chevron-white-left.b417ee54.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    display: inline-block;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.slick-next:before,
[dir=rtl] .slick-prev:before {
  content: ' ';
  background-image: url(/static/media/chevron-white-left.b417ee54.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  display: inline-block;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.slick-next {
    right: 45px
}

[dir=rtl] .slick-next {
    right: auto;
    left: 25px
}

[dir=rtl] .slick-next:before {
    content: '>'
}

.slick-dotted.slick-slider {
    margin-bottom: 30px
}

.slick-dots {
    bottom: 16px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 8px;
    padding: 0;
    cursor: pointer
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0
}

.slick-dots li button:focus,
.slick-dots li button:hover {
    outline: 0
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
    opacity: 1
}

.slick-dots li button:before {
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    content: ' ';
    text-align: center;
    opacity: 1;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 50%;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    background-color: white;
}

/*# sourceMappingURL=slick-theme.min.css.map */
